.Pagination {
  margin-right: 20px;
  text-align: right;
}

.Pagination-button {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #fff;
  transition: background-color .2s;
  cursor: pointer;
  margin: 0 10px;
  width: 44px;
  height: 34px;
  vertical-align: baseline;
}

.Pagination-button:hover {
  background-color: #eee;
  color: #23527c;
}

.Pagination-button:focus {
  outline: none;
}

.Pagination-button:disabled {
  color: #666;
  background-color: #ccc;
  cursor: not-allowed;
}

.Pagination-info {
  font-size: small;
}
