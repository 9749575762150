.AlertBox-dismiss-btn {
    padding: 3px 6px;
    margin: 0px 1px;
}

.AlertBox-container .ibox-content .ibox .ibox-title {
    border-top: none;
}

.AlertBox-container .ibox-content .ibox table{
    margin-bottom: 0px;
}

.AlertBox-container .ibox-content .ibox {
    margin-bottom: 10px;
    margin-top: 0px;
}

.AlertBox-container .ibox-content {
    padding-bottom: 0px;
}

.AlertBox-h5 {
    margin-top: 7px !important;
}

.AlertBox-title {
    min-height: 60px !important;
}

.AlertBox-overlay {
    position: absolute;
    top: 0;
    left: 1%;
    width:98%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 1;
    padding: 50px;
}

.AlertBox-HSpacer {
    display: inline-block;
    width: 1px;
    border: 1px solid #f9f9f9;
    margin: 0 15px 0 15px;
    height: 2.3em;
    vertical-align: middle
}

