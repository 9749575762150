.SelectRestList-Loading { 
    margin-left: 10px;
    vertical-align: middle;
}

.form-control.SelectRestList-Select { 
    width: 90%;
    display: inline;
}

