.UnassignedValves-btn {
    padding: 3px 6px;
    margin: 4px 0;
}

.UnassignedValves-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 1;
    padding: 50px;
}

.UnassignedValves-overlay #UnassignedValves-assign {
    z-index: 2;
}

.UnassignedValves-room-info {
    font-family: monospace;
    font-size: small;
    text-align: right;
    border-collapse: separate;
    border-spacing: 5px 0px;
    height: 40px;
    margin: 15px 0;
}

.horizontal-center {
    position: relative;
    min-height: 70px;
}

.UnassignedValves-assign-btn {
    height: 30px;
    margin: 20px 0;
}

