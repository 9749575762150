body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
}
