.ValveDetailView-Loading {
    text-align: center;
    margin: auto 0 auto 0;
}

.ValveDetailView-ContentBoxContainer {
    width: 95%;
    margin-left: 5%;
}

.ValveDetailView-ContentBoxContainer .row {
    margin-bottom: 0.5em;
}

.ValveDetailView-Box {
    min-height: 200px;
}

.ValveDetailView-alert {
    padding: 5px;
    margin-bottom: 0px;
}

text.highcharts-credits {
    display: none;
}

@keyframes battery-charging {
    0% { content: "\f244"; }
    20% { content: "\f243"; }
    40% { content: "\f242"; }
    60% { content: "\f241"; }
    80% { content: "\f240"; }
}
.fa-battery-charging:before {
    content: "\f244";
    animation: 2s battery-charging infinite;
}
