.App {
    text-align: center;
}

.App-header {
    height: 40px;
    background-color: white;
    border-radius: 0px;
    padding: 0.5rem;
    box-shadow: 0 2px 3px 0 hsla(0, 0%, 0%, 0.2);
    border-bottom: 1px solid hsl(217, 69%, 80%);
    font-family: monospace;
}

.App-logo {
    color: hsla(0, 0%, 0%, 0.8);
    margin-right: 4px;
}

.App-title {
    display: inline-flex;
    font-size: 1em;
    text-align: left;
    float: left;
    line-height: 24px;
}

.App-user {
    display: inline-flex;
    font-size: 1em;
    text-align: right;
    float: right;
    line-height: 40px;
    margin-right: 10px;
}

.App-logout {
    display: inline-flex;
    position: relative;
    font-size: large;
    float: right;
    top: 2px;
}

