.RoomSearch {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 10px 10px;
  padding: 0 10px;
  border: 1px solid #e5e6e7;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.RoomSearch :focus {
    border-color: #1ab394;
}

.RoomSearch-icon {
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 18px;
  height: 18px;
}

.RoomSearch-input {
  box-sizing: border-box;
  border: 0;
  padding-left: 20px;
  transition: opacity .2s;
  height: 45px;
  width: 90%;
}

.RoomSearch-input:focus {
  outline: none;
  opacity: 1;
}

.RoomSearch :placeholder {
  opacity: 0.8;
}

.RoomSearch-loading {
  position: absolute;
  top: 9px;
  right: 28px;
}

.RoomSearch-result-container {
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  border-radius: 2px;
  background-color: white;
}

.RoomSearch-result {
  padding: 5px 10px;
  cursor: pointer;
}

.RoomSearch-result:hover {
  background-color: #eee;
}

.RoomSearch-no-result {
  padding: 5px;
}

