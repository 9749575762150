.Table-Heading {
    line-height: 34px;
}

.Table-Loading {
    margin: 10px auto;
}

.Table-Title.ibox-title {
    min-height: 58px;
}
