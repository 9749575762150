.Loading {
  display: inline-block;
  border: 2px solid hsl(217, 82%, 34%);
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
}

.Loading-centre {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}
