
.Login-container{
    background-color: hsl(0, 0%, 93%);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: sans-serif;
}

.Login-card{
    background-color: white;
    border-radius: 0px;
    padding: 1rem;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    border-top: 6px solid hsl(217, 82%, 34%);
}

.Login-card > form{
    display: flex;
    flex-direction: column;
}

.Login-card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
    color: hsl(0, 0%, 13%);
    font-weight: bold;
}

.Login-form-item{
    padding: 5px;
    margin-bottom: 2rem;
    height: 40px;
    width: 27rem;
    border: 1px solid grey;
}

.Login-form-submit{
    height: 35px;
    color: hsl(217, 82%, 80%);
    background-color: hsl(217, 82%, 34%);
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.Login-form-submit:hover{
   opacity: 0.6;
}

.Login-error {
    width: 276px;
    position: fixed;
    top: 5px;
    left: 50%;
    z-index: 99;
    margin: 0 auto;
    transform: translate(-50%, 0);

    height: 40px;
    line-height: 40px;
    background-color: #fff;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    border-left: 4px solid hsl(0, 82%, 66%);
    text-align: center;
}

.Login-error-icon {
    display: inline-block;
    width: 15%;
    text-align: center;
    color: hsl(0, 82%, 66%);
    
}

.Login-error-text {
    width: 80%;
    display: inline-block;
    text-align: left;
}

.Login-user-redirect-message{
    width: 27rem;
    padding: 10px;
    line-height: 24px;
    font-size: small;
    text-align: center;
    background-color:hsla(0, 0%, 0%, 0.05);
    margin-top: 20px;
}
